@import "../../variables.scss";

.input-box {
  position: relative;

  span {
    position: absolute;
    cursor: pointer;
    right: 10px;
    top: 55%;
    z-index: 2;
    width: max-content;
    // svg {
    //   width: 15px;
    //   height: 15px;
    // }
  }
  label {
    color: $blackShade1;
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 5px;
    display: block;
  }
  input {
    z-index: 1;
    color: $blackShade2;
    font-size: 14px;
    font-weight: 400;
    padding: 8px 30px 8px 12px;
    border: 1px solid #ececec;
    border-radius: 8px;
    &::placeholder {
      color: $blackShade3;
    }
  }
}

@import "./variables.scss";
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  font-family: "Open Sans", sans-serif;
  color: $blackShade2;
}

#root {
  width: 100%;
  height: 100%;
}
a {
  color: $blackShade2;
  text-decoration: none;
}

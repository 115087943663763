@import "../../variables.scss";

.login-page {
  width: 100%;
  height: 100%;
  background-color: #3edccf0d;
  background-image: url("../../assets/Images/ringover-big.svg");
  background-repeat: no-repeat;
  background-position: right;
  display: flex;
  align-items: center;
  justify-content: center;
  .ringover-logo {
    position: absolute;
    top: 1.2rem;
    left: 2rem;
    svg {
      transform: translateY(6px);
      margin-right: 4px;
    }
    span {
      font-weight: 600;
      font-size: 1.6rem;
      font-family: "Poppins";
      color: $primaryBlack;
      letter-spacing: -1px;
    }
  }
  .login-box-container {
    background-image: url("../../assets//Images/curveBackground.svg");
    background-size: contain;
    background-position: -1.2px bottom;
    background-repeat: no-repeat;
    background-color: white;
    border: 1px solid #3edccf;
    border-radius: 10px;
    width: 376px;
    margin: 0;
    .login-box {
      padding: 1.5rem 1.5rem 1.5rem 1.5rem;
      display: flex;
      flex-direction: column;
      gap: 1.2rem;
      justify-content: space-between;
      .heading {
        font-family: "Open Sans";
        font-size: 1.4rem;
        color: $primaryBlue;
        font-weight: 600;
        line-height: 27px;
        letter-spacing: -0.04em;
        margin-bottom: 1rem;
      }
      .failure {
        color: #982329;
        text-align: center;
      }
      .submit {
        text-align: center;
        margin: 1rem 0;
        button {
          padding: 0.7rem 2.4rem;
          letter-spacing: 0.3px;
          position: relative;
          svg {
            position: absolute;
            transform: translateY(1px);
            right: 12px;
          }
        }
      }
    }
  }
}

@import "../../variables.scss";

button {
  padding: 12px 18px;
  background: $orange-grad;
  color: white;
  outline: none;
  border: none;
  font-family: "Open Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 22px;
  letter-spacing: -0.04em;
  border-radius: 8px;
  cursor: pointer;
  box-shadow: 0px 4px 25px 20px rgba(250, 128, 114, 0.068);
  &:hover {
    opacity: 0.7;
  }
  &.disabled {
    opacity: 0.3;
    cursor: default;
    background-color: $orange-grad;
  }
}

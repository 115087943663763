$aquamarineShade1: #3edccf0d;
$aquamarineGrad: linear-gradient(136.11deg, rgba(64, 224, 207, 0.06) 10.71%, rgba(54, 205, 207, 0.06) 98.27%);
$aquamarineShade2: #3edccf05;
$aquamarineShade3: #3edccf07;
$orange-grad: linear-gradient(86.86deg, #ff754a 9.9%, #ffa92d 96.81%);

$primaryBlue: #2d5380;

$primaryBlack: #1f1e1f;
$blackShade1: #404040;
$blackShade2: #595959;
$blackShade3: #939393;
$blackShade4: #bfbfbf;

@mixin aq-gt() {
  background: -webkit-linear-gradient(136.11deg, rgba(64, 224, 207, 0.06) 10.71%, rgba(54, 205, 207, 0.06) 98.27%);
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
}

@import "../../variables.scss";
@import "../../variables.scss";

.dashboard {
  width: 100%;
  height: 100%;
  background: #3edccf05;

  .ringover-logo {
    position: absolute;
    top: 1.2rem;
    left: 2rem;
    svg {
      transform: translateY(6px);
      margin-right: 4px;
    }
    span {
      font-weight: 600;
      font-size: 1.6rem;
      font-family: "Poppins";
      color: $primaryBlack;
      letter-spacing: -1px;
    }
  }
  .logout {
    position: absolute;
    top: 2rem;
    right: 2.5rem;
    cursor: pointer;
    z-index: 2;
    svg {
      color: red;
      width: 24px;
      transform: translateY(2px);
    }
  }
  .table-container {
    position: absolute;
    top: 5rem;
    width: 95%;
    margin-left: 2.5%;
    display: flex;
    justify-content: center;
    align-items: center;
    bottom: 1rem;
    overflow: visible;
    @media screen and (max-width: 1000px) {
      width: 90%;
      margin-left: 5%;
    }
    @media screen and (max-width: 600px) {
      width: 95%;
      margin-left: 2.5%;
    }
  }
}

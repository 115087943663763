@import "../../variables.scss";
@import "../../variables.scss";

.extractedData {
  width: 100%;
  height: 100%;
  background: #3edccf05;

  .ringover-logo {
    position: absolute;
    top: 1.2rem;
    left: 2rem;
    svg {
      transform: translateY(6px);
      margin-right: 4px;
    }
    span {
      font-weight: 600;
      font-size: 1.6rem;
      font-family: "Poppins";
      color: $primaryBlack;
      letter-spacing: -1px;
    }
  }
  .logout {
    position: absolute;
    top: 2rem;
    right: 2.5rem;
    cursor: pointer;
    z-index: 2;
    svg {
      color: red;
      width: 24px;
      transform: translateY(2px);
    }
  }

  .back {
    color: $primaryBlue;
    position: absolute;
    top: 1.9rem;
    left: 12.3rem;
    font-weight: 600;
    font-size: 16px;
    cursor: pointer;
  }

  .table-container {
    position: absolute;
    top: 5rem;
    left: 3.5rem;
    right: 3.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    bottom: 2rem;

    @media screen and (max-width: 1000px) {
      left: 4rem;
      right: 4rem;
    }
    @media screen and (max-width: 600px) {
      left: 2rem;
      right: 2rem;
    }
  }
}

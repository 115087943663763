@import "~ag-grid-community/src/styles/ag-grid.scss";
@import "~ag-grid-community/src/styles/ag-theme-material/sass/ag-theme-material-mixin";
@import "../../variables.scss";

.extractedData-ag-table {
  width: 100%;
  height: 100%;
  box-shadow: 0px 0px 10px 8px #3edccf0c;
  .ag-theme-material {
    height: 100%;
    @include ag-theme-material(
      (
        foreground-color: $blackShade2,
        background-color: white,
        data-color: ag-derived(foreground-color),
        border-color: $aquamarineShade3,
        secondary-border-color: ag-derived(border-color),
        row-border-color: ag-derived(secondary-border-color),
        cell-horizontal-padding: ag-derived(grid-size, $times: 1),
        cell-horizontal-border: solid $aquamarineShade3,
        grid-size: 4px,
        row-height: ag-derived(grid-size, $times: 6, $plus: 1),
        borders: true,
        header-column-separator: true,
        header-column-separator-width: 10px,
        header-column-separator-height: 100%,
        header-column-separator-color: ag-derived(border-color, $opacity: 0.03),
        full-width-tabs: true,
        header-cell-hover-background-color: null,
        row-hover-color: $aquamarineShade3,
      )
    );
    .ag-header {
      padding: 0;
      margin: 0;

      .ag-header-cell {
        border: 1px solid $aquamarineShade3;
        margin: 0;
        margin-left: -6px;
        justify-content: space-around;
        .ag-header-cell-label {
          font-family: "Open Sans";
          font-weight: 600;
          font-size: 16px;
          color: $primaryBlue;
          padding-left: 22px;
        }
        &::after {
          width: 0;
        }
      }
    }
    .ag-row {
      .ag-cell {
        margin-left: -1px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        height: 69px;
        font-size: 14px;
        border: 4px solid $aquamarineShade3;
      }
      .ag-cell-focus {
        border: none !important;
        outline: none;
      }
    }
  }
}
.extractedData-ag-table * {
  &::-webkit-scrollbar {
    display: none;
    width: 0;
    height: 0;
  }
  .ag-paging-panel {
    margin: 0;
    font-size: 14px;
    font-family: "Work Sans";
    font-weight: 400;
    line-height: 19px;
    letter-spacing: 0.25px;
    color: $blackShade2 !important;
    padding-right: 3rem;
    position: fixed;
    top: 2rem;
    right: 2rem;
    @media screen and (max-width: 600px) {
      right: 0rem;
      margin-left: 12rem;
      top: 1.7rem;
    }
    .ag-icon {
      color: $blackShade1;
    }
  }
  .ag-body-horizontal-scroll {
    height: 0 !important;
    max-height: 0 !important;
    display: none;
  }
}
